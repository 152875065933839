import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Triangle: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M13.73 4a2 2 0 0 0-3.46 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z',
      },
    ],
  ],
];

export default Triangle;
