import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Clapperboard: IconNode = [
  'svg',
  defaultAttributes,
  [
    [
      'path',
      {
        d: 'M20.2 6 3 11l-.9-2.4c-.3-1.1.3-2.2 1.3-2.5l13.5-4c1.1-.3 2.2.3 2.5 1.3Z',
      },
    ],
    ['path', { d: 'm6.2 5.3 3.1 3.9' }],
    ['path', { d: 'm12.4 3.4 3.1 4' }],
    ['path', { d: 'M3 11h18v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Z' }],
  ],
];

export default Clapperboard;
