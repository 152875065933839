import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Slash: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M22 2 2 22' }]],
];

export default Slash;
