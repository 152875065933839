import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

const Check: IconNode = [
  'svg',
  defaultAttributes,
  [['path', { d: 'M20 6 9 17l-5-5' }]],
];

export default Check;
